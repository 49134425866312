<template>
    <div>
    <div v-if="cargando">
        <v-skeleton-loader
            class="mx-auto"
            type="article, article"
        ></v-skeleton-loader>
    </div>

        <v-alert type="error" v-if="!internamiento && !paciente" class="d-block">
            Parámetros no configurados.
        </v-alert>

        <v-alert
            v-if="total==0"
            class="d-block"

            type="warning"
            border="left"
            colored-border
            elevation="2"
        >
            No hay indicaciones que mostrar.
        </v-alert>


            <v-btn class="ml-5 mt-2" rounded outlined color="green" @click="cargaNuevaIndicacion" :disabled="nuevaIndicacion" v-show="permiteAgrega">
                <v-icon  left>mdi-file-plus-outline</v-icon> Nueva indcación
            </v-btn>
            <!-- Para la nueva indicación -->

            <v-expand-transition>
                <div class="orange lighten-5 mx-5 mt-2 pa-2" v-if="nuevaIndicacion">
                    <span class="title">Nuevas indicaciones: </span>
                    <div>



                        <editor
                            v-model="indicacion"
                            api-key="5ao0kvhebp3vinnv26st7ce6tgkpakhs1xa30yiwt7w6qrc4"
                            :init="{
                                height: 500,
                                menubar: false,
                                plugins: [
                                    'lists link',
                                ],
                                toolbar:
                                'bold italic|bullist numlist'
                            }"
                            />

                    </div>

                    <div class="d-flex flex-row">
                        <v-spacer/>
                        <v-btn
                            color="error"
                            text
                            @click="cancela"
                        >
                            Cancelar
                        </v-btn>

                        <v-btn
                            color="success"

                            :loading="guardandoIndica"
                            @click="guarda"
                        >
                            Guardar
                        </v-btn>
                    </div>
                </div>
            </v-expand-transition>

        <v-list v-if="!cargando"
        >






            <v-list-item
                v-for="(indica, id) in datos"
                :key="id"
                class="d-block"

            >
                    <v-toolbar
                        color="yellow darken-2"
                        dark
                        dense
                        flat
                    >
                        <v-toolbar-title>{{indica.cama}}</v-toolbar-title>
                        <v-spacer/>
                        {{indica.dateCreated}}
                        <v-icon class="pl-2" v-if="permiteImprimir" @click="muestraImprime(indica.id)">mdi-printer</v-icon>
                    </v-toolbar>
                <v-sheet class="mb-2 pa-3" elevation="2">

                        <span
                            v-html="indica.indicaciones"
                        >
                        </span>
                        <v-divider/>
                        <div class="text-right">
                            <span class="subtitle-2 font-weight-black">{{indica.medico.nombres}} {{indica.medico.apellidoPaterno}} {{indica.medico.apellidoMaterno}}</span><br/>
                            <span>{{indica.medico.especialidad}} ({{indica.medico.cedula}})</span><br/>
                            <span><b>CURP:</b> {{indica.medico.curp}}</span>
                        </div>
                </v-sheet>

            </v-list-item>

        </v-list>

        <div v-if="!cargando">
            <v-pagination
                circle
                v-model="page"
                :length="pageLength"
                total-visible="7"
                @input="cargaIndica"
            ></v-pagination>

            <div class="text-center caption">
                <b>{{pageDe}}</b> a <b>{{pageA}}</b> de <b>{{total}}</b>
            </div>
        </div>

        <!-- Dialogo para imprimir -->

        <v-dialog
            width="350"
            v-model="dialogImprime"
        >

            <v-card>
                <v-form
                    v-model="validaImprime"
                >
                    <v-card-title
                        class="headline orange"
                        primary-title
                    >
                        <span class="white--text">Imprime INIDCACIONES</span>
                    </v-card-title>

                    <v-card-text class="pa-5">
                        <v-text-field
                            v-model="altura"
                            type="number"
                            :rules="[
                                value => !!value || 'Requerido.',
                                value => value >= 5 || 'Este número no es válido',
                                value => value <= 23 || 'Este número no es válido',
                            ]"
                            label="Altura"

                        />
                        <v-checkbox
                            v-model="encabezado"
                            label="Encabezado"
                        >
                        </v-checkbox>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer/>
                        <v-btn
                            color="primary"
                            @click="imprimeIndica"
                            :disabled="!validaImprime"
                        >
                            Imprimir
                        </v-btn>
                    </v-card-actions>
                </v-form>
            </v-card>
        </v-dialog>


    </div>
</template>

<script>
import Editor from '@tinymce/tinymce-vue'
export default {
    mounted(){
        if (this.internamiento){
            this.params.internamiento = this.internamiento
            this.cargaIndica()
        }

        if (this.paciente){
            this.params.cip = this.paciente
            this.params.paciente = this.paciente
            this.cargaIndica()
        }
    },
    components:{
        Editor
    },
    data: ()=>({


        page:1,
        cargando:true,
        datos:{},
        total:null,
        params:{},

        validaImprime:false,
        dialogImprime:false,
        estaIndica:null,
        altura:5,
        encabezado:true,

        nuevaIndicacion:false,
        indicacion:'',
        customToolbar: [["bold", "italic", "underline"]],
        guardandoIndica:false,

    }),
    props:{
        permiteImprimir:{
            type: Boolean,
            default:true,
        },
        permiteAgrega:{
            type:Boolean,
            default: true,
        },
        internamiento:{
            type:Number,
        },
        paciente:{
            type:Number,
        }
    },

    watch:{

        internamiento: function(nv){
            this.params={
                'internamiento':nv
            }
            this.cargaIndica()
        },
        paciente: function(nv){
            this.params={
                'paciente':nv
            }
            this.cargaIndica()
        }

    },

    methods:{

        muestraImprime(indica){
            //console.log(indica)
            this.estaIndica = indica
            this.dialogImprime = true
        },

        imprimeIndica(){
            this.dialogImprime = false
            let url = this.$baseUrl+'hospital/imprimeindicaciones/?id='+this.estaIndica+'&se='+this.altura+'&en='+this.encabezado
            window.open(url,'winname','directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=no,width=800,height=600')
            //http://localhost:9999/secah2015/hospital/imprimeindicaciones?id=177736&se=9&en=true

        },

        async cargaNuevaIndicacion(){
            
            this.nuevaIndicacion = true
            
            try{
                let ind = await this.$http({
                    method: 'POST',
                    url:'/hospital/getUltimaIndicaV2',
                    params:{id:this.internamiento}
                })
                
                if (ind.data.total >=1){

                    let txt = ind.data.indicaciones

                    this.indicacion=txt

                } else {
                    this.indicacion = '<b>DIETA</b><br/><br/>'+
                    '<b>SOLUCIONES</b><br/><br/>'+
                    '<b>MEDICAMENTOS</b><br/><br/>'+
                    '<b>CUIDADOS GENERALES</b><br/><br/>'
                    '<b>HEMOCOMPONENTES</b><br/><br/>'
                    '<b>OTROS</b><br/><br/>'

                }

            } catch(err) {
                this.$store.dispatch('security/revisaError',err)
            }



        },
        cancela(){
            this.nuevaIndicacion=false
            this.indicacion = ''
            //this.$emit('update:datoNotaExterna',{})
        },
        async guarda(){
            this.guardandoIndica = true
            try{
                let params = {
                    internamiento:this.internamiento,
                    indicaciones:this.indicacion
                }

                let ind = await this.$http({
                    method:'POST',
                    url:'/hospital/createindicaciones',
                    params:params
                })
                this.guardandoIndica = false
                
                if (ind.data.estado == 'true'){
                    this.nuevaIndicacion=false
                    this.indicacion = ''
                    this.cargaIndica()
                } else {
                    console.log(ind.data)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede guardar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }

            } catch(err){
                this.guardandoIndica = false
                this.$store.dispatch('security/revisaError',err)
            }
        },
        async cargaIndica(){

            this.cargando = true

            this.params.page = this.page
            this.params.start = (this.page -1) * 10
            this.params.limit = 10


            try{
                let not = await this.$http.get('hospital/listaindicaencuentro',{
                    params: this.params
                })
                this.cargando = false
                let dc = not.data;
                if (dc){
                    this.datos= dc.root
                    this.total = dc.total
                    //this.pageLength = dc.total / 10
                    //console.log(me.datos)
                    //console.log(me.internados);
                } else {
                    console.log(dc)
                    this.$swal.fire({
                        icon: 'warning',
                        title: 'No se puede cargar',
                        text: 'Verifique los datos e intente de nuevo',
                    })
                }
            }
            catch(err){
                this.$store.dispatch('security/revisaError',err)
                this.cargando = false
            }
        
        }
    },

    computed: {
        pageLength: function(){
            return (Math.ceil(this.total / 10));
        },
        pageDe: function(){
            return (((this.page - 1) * 10)+1);
        },
        pageA: function(){
            let pa = this.page * 10
            
            if (pa > this.total ){
                pa = this.total
            }
            return (pa)
        }
    },
}
</script>

<style>

</style>